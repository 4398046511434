import { useRef } from 'react';
import userPermissionsDictionary from 'constants/dictionary/userPermissionsDictionary';
import { hasPermission } from 'libs/storageLibs';
import { isEqual } from 'lodash';

const usePermissions = () => {
  const permissions = {
    advertiserBalanceManual: hasPermission([userPermissionsDictionary.types.VISTA_ADVERTISER_BALANCE]),
    advertiserRead: hasPermission([userPermissionsDictionary.types.VISTA_ADVERTISER_READ]),
    advertiserWrite: hasPermission([userPermissionsDictionary.types.VISTA_ADVERTISER_WRITE]),
    campaignApproval: hasPermission([userPermissionsDictionary.types.VISTA_CAMPAIGN_APPROVAL]),
    campaignRead: hasPermission([userPermissionsDictionary.types.VISTA_CAMPAIGN_READ]),
    campaignWrite: hasPermission([userPermissionsDictionary.types.VISTA_CAMPAIGN_WRITE]),
    creativeRead: hasPermission([userPermissionsDictionary.types.VISTA_CREATIVE_READ]),
    creativeWrite: hasPermission([userPermissionsDictionary.types.VISTA_CREATIVE_WRITE]),
  };
  const permissionsRef = useRef(permissions);
  if (!isEqual(permissions, permissionsRef.current)) {
    permissionsRef.current = permissions;
  }

  return permissionsRef.current;
};

export default usePermissions;
