// Libs
import { buildHashMap } from 'libs/buildHashMap';

const types = {
  VISTA_ADVERTISER_BALANCE: 'VISTA_ADVERTISER_BALANCE',
  VISTA_ADVERTISER_READ: 'VISTA_ADVERTISER_READ',
  VISTA_ADVERTISER_WRITE: 'VISTA_ADVERTISER_WRITE',
  VISTA_CAMPAIGN_APPROVAL: 'VISTA_CAMPAIGN_APPROVAL',
  VISTA_CAMPAIGN_READ: 'VISTA_CAMPAIGN_READ',
  VISTA_CAMPAIGN_WRITE: 'VISTA_CAMPAIGN_WRITE',
  VISTA_CREATIVE_READ: 'VISTA_CREATIVE_READ',
  VISTA_CREATIVE_WRITE: 'VISTA_CREATIVE_WRITE',
  VISTA_DASHBOARD_ACTIVITY: 'VISTA_DASHBOARD_ACTIVITY',
  VISTA_REPORT_READ: 'VISTA_REPORT_READ',
  VISTA_ADVERTISER_MULTIPLE: ' VISTA_ADVERTISER_MULTIPLE',
};

const dictionary = [
  { id: types.VISTA_ADVERTISER_BALANCE, name: 'Advertisers Balance Manual' },
  { id: types.VISTA_ADVERTISER_READ, name: 'Read access to the assigned Advertisers' },
  { id: types.VISTA_ADVERTISER_WRITE, name: 'Write access to the assigned Advertisers' },
  { id: types.VISTA_CAMPAIGN_APPROVAL, name: 'Access to Campaign Approval functionality' },
  { id: types.VISTA_CAMPAIGN_READ, name: 'Read access to the Campaigns page' },
  { id: types.VISTA_CAMPAIGN_WRITE, name: 'Write access to the Campaigns page' },
  { id: types.VISTA_CREATIVE_READ, name: 'Read access to the Creatives page' },
  { id: types.VISTA_CREATIVE_WRITE, name: 'Write access to the Creatives page' },
  { id: types.VISTA_DASHBOARD_ACTIVITY, name: 'Access to the Activity Dashboard Tab' },
  { id: types.VISTA_REPORT_READ, name: 'Read Access to Reports' },
  { id: types.VISTA_ADVERTISER_MULTIPLE, name: 'Allows user to have multiple advertisers' },
];

const hashMap = buildHashMap(dictionary);

export default {
  types,
  dictionary,
  hashMap,
};
