import UserPermissions from './userPermissionsDictionary';

// <API_URL>/supported_account_types
// [
//   {
//       "id": 13,
//       "name": "Vista Agency",
//       "status": null,
//       "externalId": null
//   },
//   {
//       "id": 14,
//       "name": "Vista Advertiser",
//       "status": null,
//       "externalId": null
//   }
// ]
// To exclude massive code changes values from the `/supported_account_types`
// been hardcoded.
const types = {
  ADVERTISER: '14',
  AGENCY: '13',
};

// Account type specific permissions
// Only Agency can see Advertiser tab
const permissions = {
  [types.ADVERTISER]: [],
  [types.AGENCY]: [UserPermissions.types.VISTA_ADVERTISER_MULTIPLE],
};

const dictionary = [
  { id: types.ADVERTISER, name: 'Brand/Business' },
  { id: types.AGENCY, name: 'Agency' },
];

export default {
  types,
  dictionary,
  permissions,
};
