import React, { memo, useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setFilters as creativeSetFilters } from 'actions/creativeActions';

// Dictionaries
import UserPermissions from 'constants/dictionary/userPermissionsDictionary';
import { INITIAL_FILTERS } from 'reducers/creativeReducer';

// Libs
import { hasPermission } from 'libs/storageLibs';
import { separateByStatus } from 'libs/separateByStatus';
import { strToArrayNumber, transformParams } from 'libs/query-string';

// Modules
import PageTitle from 'modules/PageTitle/PageTitle';

// Hooks
import useGetParameters from 'hooks/useGetParameters';
import useQueryParams from 'hooks/useQueryParams';
import usePermissions from 'hooks/usePermissions';
import ProfileHeader from 'modules/ProfileHeader/ProfileHeader';
import useCreativeList from './useCreativeList';

// Components
import Filters from './Filters';
import TableCreatives from './TableCreatives';
import PreviewModal from './PreviewModal';
import { useUpdateCreativeStatusMutation } from '../../creativeApiSlice';
import { useGetShortAdvertiserListQuery, useGetShortCampaignListQuery } from '../../../../api/apiSlice';

const getParamNames = (permissions) => {
  const paramNames = ['search', 'type', 'status'];
  if (permissions.advertiserRead) paramNames.push('advertisers');
  if (permissions.campaignRead) paramNames.push('campaigns');

  return paramNames;
};

function CreativeList() {
  const [previewModal, setPreviewModal] = useState({
    isOpen: false,
    creative: null,
  });

  const dispatch = useDispatch();
  const filters = useSelector((state) => state.creatives.filters);
  const setFilters = useCallback((values) => dispatch(creativeSetFilters(values)), [dispatch]);

  const tableRef = useRef();
  const pageRef = useRef(0);
  const refFilters = useRef(filters);

  const [updateCreativeStatus] = useUpdateCreativeStatusMutation();
  const { data: shortAdvertiserList = [] } = useGetShortAdvertiserListQuery({ size: 10000 });
  const { data: shortCampaignList = [] } = useGetShortCampaignListQuery({ size: 10000, type: 'VIDEO' });
  const advertiserOptions = useMemo(() => separateByStatus(shortAdvertiserList), [shortAdvertiserList]);
  const { requestCreatives, meta } = useCreativeList(filters);
  const permissions = usePermissions();
  const PARAM_NAMES = useMemo(() => getParamNames(permissions), [permissions]);
  const params = useGetParameters(PARAM_NAMES);
  const { debouncedReplaceQueryParams, replaceQueryParams } = useQueryParams();

  const updateStatus = useCallback((id, status) => {
    updateCreativeStatus({ id, data: JSON.stringify(status) }).unwrap().then(() => (tableRef?.current?.onQueryChange()));
  }, [updateCreativeStatus]);

  useEffect(() => {
    const config = {
      search: (val) => val,
      type: (val) => val,
      status: (val) => val,
      advertisers: strToArrayNumber,
      campaigns: strToArrayNumber,
    };

    if (Object.keys(params).length) {
      const filterValues = { ...INITIAL_FILTERS, ...transformParams(params, config) };
      setFilters(filterValues);
      refFilters.current = filterValues;
    } else {
      replaceQueryParams(refFilters.current);
    }
  }, [params, setFilters, replaceQueryParams]);

  const refreshCreativeList = useCallback(() => {
    if (!tableRef.current) return;
    tableRef.current.onQueryChange();
  }, []);

  const handleChangeFilters = useCallback((values) => {
    pageRef.current = 0;
    setFilters(values);
    refFilters.current = values;
    debouncedReplaceQueryParams(values, refreshCreativeList);
  }, [setFilters, debouncedReplaceQueryParams, refreshCreativeList]);

  const resetFilters = useCallback(() => {
    handleChangeFilters(INITIAL_FILTERS);
  }, [handleChangeFilters]);

  const togglePreviewModal = useCallback((creative = null) => {
    setPreviewModal((prevState) => ({
      isOpen: !prevState.isOpen,
      creative,
    }));
  }, [setPreviewModal]);

  const isEditable = hasPermission([UserPermissions.types.VISTA_CREATIVE_WRITE]);

  const onChangeFilters = (name) => (value) => {
    handleChangeFilters({ ...filters, [name]: value });
  };

  // reset filters on leave
  useEffect(() => () => {
    pageRef.current = 0;
    refFilters.current = INITIAL_FILTERS;
    setFilters(INITIAL_FILTERS);
  }, [setFilters]);

  return (
    <>
      <ProfileHeader filters={ filters } onChangeFilters={ onChangeFilters } />
      <Grid container justifyContent="space-between" className="mb-5">
        <Grid item>
          <PageTitle title="Creatives Management" showBreadcrumbs />
        </Grid>
        {isEditable && (
          <Grid item>
            <Button
              component={ Link }
              to="add"
              variant="contained"
              color="primary"
            >
              Add Creative
            </Button>
          </Grid>
        )}
      </Grid>

      <div className="grayContainer">
        <div className="mb-3">
          <Filters
            values={ filters }
            onChange={ handleChangeFilters }
            onReset={ resetFilters }
            advertiserList={ advertiserOptions }
            campaignList={ shortCampaignList }
          />
        </div>

        <TableCreatives
          tableRef={ tableRef }
          pageRef={ pageRef }
          getCreativeList={ requestCreatives }
          updateStatus={ updateStatus }
          meta={ meta }
          openPreview={ togglePreviewModal }
        />
      </div>

      <PreviewModal
        isOpen={ previewModal.isOpen }
        creative={ previewModal.creative }
        handleClose={ togglePreviewModal }
      />
    </>
  );
}

export default memo(CreativeList);
