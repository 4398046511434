import { TabStep } from 'classes/tabStep';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import ImageRoundedIcon from '@mui/icons-material/ImageRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
// import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import UserPermissions from './dictionary/userPermissionsDictionary';

export const AdvertiserSteps = [
  new TabStep({ id: 0, name: 'General', touched: true }),
  new TabStep({ id: 1, name: 'Contacts' }),
  new TabStep({ id: 2, name: 'Balance' }),
];

export const BalanceTypes = [
  { id: 'POSITIVE', name: 'Positive' },
  { id: 'NEGATIVE', name: 'Negative' },
];

export const CampaignSteps = [
  new TabStep({ id: 0, name: 'Campaign Details', touched: true }),
  new TabStep({ id: 1, name: 'Ad Copy' }),
  new TabStep({ id: 2, name: 'Targeting' }),
  new TabStep({ id: 3, name: 'Budget' }),
  new TabStep({ id: 4, name: 'Review' }),
];

export const NavLinks = [
  {
    id: '/dashboard',
    icon: DashboardRoundedIcon,
    name: 'Overview',
    children: null,
    permissions: [UserPermissions.types.VISTA_DASHBOARD_ACTIVITY],
  },
  {
    id: '/advertisers',
    icon: PeopleAltRoundedIcon,
    name: 'Advertisers',
    children: {
      id: '/advertisers/add',
      name: 'Add Advertiser',
    },
    permissions: [UserPermissions.types.VISTA_ADVERTISER_READ, UserPermissions.types.VISTA_ADVERTISER_MULTIPLE],
  },
  {
    id: '/campaigns',
    icon: BarChartRoundedIcon,
    name: 'Campaigns',
    children: {
      id: '/campaigns/create',
      name: 'Add Campaign',
    },
    permissions: [UserPermissions.types.VISTA_CAMPAIGN_READ],
  },
  {
    id: '/creatives',
    icon: ImageRoundedIcon,
    name: 'Creatives',
    children: {
      id: '/creatives/add',
      name: 'Add Creative',
    },
    permissions: [UserPermissions.types.VISTA_CREATIVE_READ],
  },
  {
    id: '/reports',
    icon: DescriptionRoundedIcon,
    name: 'Reports',
    children: null,
    permissions: [UserPermissions.types.VISTA_REPORT_READ],
  },
  // TODO: Wouldn't be used. If refactoring required remove completely.
  // {
  //   id: '/insights',
  //   icon: InsightsRoundedIcon,
  //   name: 'Insights',
  //   children: null,
  //   permissions: [UserPermissions.types.INSIGHTS],
  // },
];

export const Statuses = [
  { id: 'ACTIVE', name: 'Active' },
  { id: 'INACTIVE', name: 'Inactive' },
];
