import { useState, useCallback, useEffect } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { hasPermission } from 'libs/storageLibs';
import { useSnackbar } from 'notistack';

// Actions
import { parseFile as parseFileReq } from 'actions/campaignActions';

// Hooks
import useFilterableStates from 'hooks/dictionary/useFilterableStates';
import useFilterableCities from 'hooks/dictionary/useFilterableCities';
import useFilterableCongressionalDistricts from 'hooks/dictionary/useFilterableCongressionalDistricts';

import userPermissionsDictionary from 'constants/dictionary/userPermissionsDictionary';
import { validateFileExtension, validateFileSize } from 'libs/validateErrors';
import { useDispatch } from 'react-redux';

// Api
import { useGetDMAsQuery } from '../../../../../api/apiSlice';

const getInitialTargetingList = (values) => {
  const checkItem = (target, key) => get(values, target) && key;

  if (values) {
    return [
      checkItem('zips.values.length', 'zips'),
      checkItem('districts.values.length', 'districts'),
      checkItem('dmas.values.length', 'dmas'),
      checkItem('cities.values.length', 'cities'),
      checkItem('states.values.length', 'states'),
      checkItem('countries.values.length', 'countries'),
    ].filter(Boolean);
  }
  return [];
};

function useGeography({ values }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [targetingList, setTargetingList] = useState(getInitialTargetingList(values));

  const { data: { DMAs = [] } = {} } = useGetDMAsQuery();

  const filterableStates = useFilterableStates();
  const filterableCities = useFilterableCities();
  const filterableCongressionalDistricts = useFilterableCongressionalDistricts();

  const { updateSearchParams: updateCities } = filterableCities;

  const parseFileAction = useCallback((formData) => dispatch(parseFileReq(formData)), [dispatch]);

  useEffect(() => {
    const countryCodes = values.countries.values.map((item) => item.id);
    updateCities({ country: countryCodes });
  }, [values.countries.values, updateCities]);

  const parseFile = useCallback((file) => {
    const error = validateFileSize(file, 10) || validateFileExtension(file.name, ['csv']);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
      return [];
    }
    const formData = new FormData();
    formData.append('file', file);
    return parseFileAction(formData);
  }, [enqueueSnackbar, parseFileAction]);

  const fields = [
    {
      id: 'states',
      name: 'states.values',
      label: 'State',
      list: filterableStates.filteredStates,
      loading: filterableStates.loading,
      updateSearchParams: filterableStates.updateSearchParams,
      visible: true,
    },
    {
      id: 'cities',
      name: 'cities.values',
      label: 'City',
      list: filterableCities.filteredCities,
      loading: filterableCities.loading,
      updateSearchParams: filterableCities.updateSearchParams,
      visible: true,
    },
    {
      id: 'dmas',
      name: 'dmas.values',
      label: 'DMA',
      list: DMAs,
      loading: false,
      updateSearchParams: () => null,
      visible: true,
    },
    {
      id: 'districts',
      name: 'districts.values',
      label: 'Congressional district',
      list: filterableCongressionalDistricts.filteredCongressionalDistricts,
      loading: filterableCongressionalDistricts.loading,
      updateSearchParams: filterableCongressionalDistricts.updateSearchParams,
      visible: hasPermission([userPermissionsDictionary.types.VISTA_CAMPAIGN_READ]),
    },
    {
      id: 'zips',
      name: 'zips.values',
      label: 'Zip Codes',
      list: [],
      loading: false,
      updateSearchParams: () => null,
      visible: true,
    },
  ];

  return { fields, targetingList, setTargetingList, parseFile };
}

useGeography.propTypes = {
  campaignForm: PropTypes.shape({
    values: PropTypes.shape({
      targeting: PropTypes.shape({
        cities: PropTypes.shape({ allow: PropTypes.bool, values: PropTypes.arrayOf(PropTypes.string) }),
        districts: PropTypes.shape({ allow: PropTypes.bool, values: PropTypes.arrayOf(PropTypes.string) }),
        countries: PropTypes.shape({ allow: PropTypes.bool, values: PropTypes.arrayOf(PropTypes.string) }),
        dmas: PropTypes.shape({ allow: PropTypes.bool, values: PropTypes.arrayOf(PropTypes.string) }),
        states: PropTypes.shape({ allow: PropTypes.bool, values: PropTypes.arrayOf(PropTypes.string) }),
        zipCodes: PropTypes.shape({ allow: PropTypes.bool, values: PropTypes.arrayOf(PropTypes.string) }),
      }),
    }),
  }).isRequired,
};

export default useGeography;
