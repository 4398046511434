import React, { memo } from 'react';
import { useParams } from 'react-router-dom';

// Libs
import { hasPermission } from 'libs/storageLibs';

// Dictionaries
import UserPermissions from 'constants/dictionary/userPermissionsDictionary';

import useEditAdvertiser from './useEditAdvertiser';
import FormAdvertiser from '../../FormAdvertiser';

function EditAdvertiser() {
  const { id } = useParams();
  const { initialValues, handleSubmit } = useEditAdvertiser(id);
  const permissionEdit = hasPermission([UserPermissions.types.VISTA_ADVERTISER_WRITE]);

  return (
    <FormAdvertiser
      initialValues={ initialValues }
      handleSubmit={ handleSubmit }
      permissionEdit={ permissionEdit }
      isEditForm
    />
  );
}

export default memo(EditAdvertiser);
