import React, { useMemo, memo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from '@mui/icons-material/RequestQuoteOutlined';
import BalanceIcon from '@mui/icons-material/AccountBalanceWalletOutlined';

// Libs
import { textFormat } from 'libs/textFormat';
import { hasPermission } from 'libs/storageLibs';

// Modules
import TableCell from 'modules/_Table/TableCell/TableCell';
import MaterialTableText from 'modules/_MaterialTable/MaterialTableText/MaterialTableText';
import MaterialTablesSortIcon from 'modules/_MaterialTable/MaterialTablesSortIcon/MaterialTablesSortIcon';
import MaterialTableContainer from 'modules/_MaterialTable/MaterialTableContainer/MaterialTableContainer';
import MaterialTableToolbar from 'modules/_MaterialTable/MaterialTableToolbar/MaterialTableToolbar';
import MaterialTableBody from 'modules/_MaterialTable/MaterialTableBody/MaterialTableBody';
import OptionsButtonNav from 'modules/OptionsButtonNav/OptionsButtonNav';
import StatusField from 'modules/StatusField/StatusField';

// Dictionaries
import UserPermissions from 'constants/dictionary/userPermissionsDictionary';
import MaterialTableAjaxPagination from 'modules/_MaterialTable/MaterialTableAjaxPagination/MaterialTableAjaxPagination';

const ROWS_PER_PAGE_OPTIONS = [10, 20, 50, 100];

function TableAdvertisers({
  getAdvertiserList,
  updateStatus,
  openRefillBalanceModal,
  openBillingHistoryModal,
  meta,
  tableRef,
  pageRef,
}) {
  const isEditable = hasPermission([UserPermissions.types.VISTA_ADVERTISER_WRITE]);

  const getOptionsItems = useCallback((rowData) => ([
    {
      type: 'button',
      label: 'Billing history',
      icon: HistoryIcon,
      onClick: () => openBillingHistoryModal({
        advertiserId: rowData.id,
        balance: rowData.balance,
      }),
    },
    {
      type: 'button',
      label: 'Refill balance',
      icon: BalanceIcon,
      onClick: () => openRefillBalanceModal({ advertiserId: rowData.id }),
    },
  ]), [openBillingHistoryModal, openRefillBalanceModal]);

  const columns = useMemo(() => [
    {
      title: 'ID',
      field: 'id',
      width: 120,
      minWidth: 120,
      render: (rowData) => (
        <MaterialTableText variant="light">
          {rowData.id}
        </MaterialTableText>
      ),
    },
    {
      title: 'NAME',
      field: 'name',
      defaultSort: 'asc',
      width: '100%',
      render: (rowData) => (
        <Link to={ `${rowData.id}` } className="link">
          <TableCell firstRow={ rowData.name } />
        </Link>
      ),
    },
    {
      title: 'BALANCE',
      field: 'availableBalance',
      width: 170,
      minWidth: 170,
      render: (rowData) => (
        <MaterialTableText variant="light">
          {textFormat(rowData.availableBalance, 'currency')}
        </MaterialTableText>
      ),
    },
    {
      title: 'CAMPAIGNS',
      field: 'campaignsCount',
      width: 160,
      minWidth: 160,
      render: (rowData) => (
        <Link to={ `../campaigns?advertiser=${rowData.id}` } className="link">
          <TableCell firstRow={ rowData.campaignsCount || 0 } />
        </Link>
      ),
    },
    {
      title: 'CREATIVES',
      field: 'creativesCount',
      width: 160,
      minWidth: 160,
      render: (rowData) => (
        <Link to={ `../creatives?advertisers=${rowData.id}` } className="link">
          <TableCell firstRow={ rowData.creativesCount || 0 } />
        </Link>
      ),
    },
    {
      title: 'STATUS',
      field: 'status',
      width: 120,
      minWidth: 120,
      render: (rowData) => (
        <StatusField
          value={ rowData.status }
          handleChange={ (e, element) => (updateStatus(rowData.id, element.props.value)) }
        />
      ),
    },
    {
      title: '',
      field: 'options',
      width: 24,
      minWidth: 24,
      sorting: false,
      cellStyle: { textAlign: 'right' },
      render: (rowData) => {
        const items = [
          { to: `${rowData.id}`, label: isEditable ? 'Edit' : 'Show', icon: EditIcon },
          ...getOptionsItems(rowData),
        ];
        return <OptionsButtonNav items={ items } />;
      },
    },
  ], [isEditable, getOptionsItems, updateStatus]);

  const dataCallback = useCallback((query) => getAdvertiserList({
    ...query,
    page: pageRef.current,
  }), [getAdvertiserList, pageRef]);

  const toolbarCallback = useCallback(
    (props) => (
      <MaterialTableToolbar tableRef={ tableRef } pageRef={ pageRef } entityName="Advertisers" { ...props } />
    ),
    [tableRef, pageRef],
  );

  const paginationCallback = useCallback(
    (props) => (
      <MaterialTableAjaxPagination pageRef={ pageRef } { ...props } />
    ),
    [pageRef],
  );

  return (
    <MaterialTable
      columns={ columns }
      tableRef={ tableRef }
      data={ dataCallback }
      localization={ { body: { emptyDataSourceMessage: <h4>Sorry, we couldn&apos;t find any results matching.</h4> } } }
      options={ {
        draggable: false,
        pageSizeOptions: ROWS_PER_PAGE_OPTIONS,
        pageSize: meta?.size || ROWS_PER_PAGE_OPTIONS[0],
        thirdSortClick: false,
      } }
      icons={ { SortArrow: MaterialTablesSortIcon } }
      components={ {
        Toolbar: toolbarCallback,
        Pagination: paginationCallback,
        Container: MaterialTableContainer,
        Body: MaterialTableBody,
      } }
    />
  );
}

TableAdvertisers.defaultProps = {
  openRefillBalanceModal: Function.prototype,
  openBillingHistoryModal: Function.prototype,
};

TableAdvertisers.propTypes = {
  tableRef: PropTypes.shape({}).isRequired,
  pageRef: PropTypes.shape({}).isRequired,
  getAdvertiserList: PropTypes.func.isRequired,
  updateStatus: PropTypes.func.isRequired,
  openRefillBalanceModal: PropTypes.func,
  openBillingHistoryModal: PropTypes.func,
  meta: PropTypes.shape({
    perPage: PropTypes.number,
    total: PropTypes.number,
    size: PropTypes.number,
  }).isRequired,
};

export default memo(TableAdvertisers);
