import React, { memo } from 'react';

// Libs
import { hasPermission } from 'libs/storageLibs';

// Hooks
import UserPermissions from 'constants/dictionary/userPermissionsDictionary';
import useAddAdvertiser from './useAddAdvertiser';

// Dictionaries
import FormAdvertiser from '../../FormAdvertiser';

function AddAdvertiser() {
  const { handleSubmit } = useAddAdvertiser();
  const permissionEdit = hasPermission([UserPermissions.types.VISTA_ADVERTISER_WRITE]);

  return (
    <FormAdvertiser handleSubmit={ handleSubmit } permissionEdit={ permissionEdit } />
  );
}

export default memo(AddAdvertiser);
