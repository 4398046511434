import React, { memo, useEffect, useCallback, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Button } from '@mui/material';

// Dictionaries
import UserPermissions from 'constants/dictionary/userPermissionsDictionary';
import { INITIAL_FILTERS } from 'reducers/advertiserReducer';

// Libs
import { hasPermission } from 'libs/storageLibs';

// Modules
import PageTitle from 'modules/PageTitle/PageTitle';

// Hook
import useGetParameters from 'hooks/useGetParameters';
import useQueryParams from 'hooks/useQueryParams';
import advertiserActions from 'actions/advertiserActions';
import ProfileHeader from 'modules/ProfileHeader/ProfileHeader';
import useAdvertiserList from './useAdvertiserList';

// Components
import Filters from './Filters';
import TableAdvertisers from './TableAdvertisers';
import RefillBalanceModal from '../../AdvertiserPopups/Popups/RefillBalanceModal';
import BillingHistoryModal from '../../AdvertiserPopups/Popups/BillingHistoryModal';
import { useUpdateAdvertiserStatusMutation } from '../../advertiserApiSlice';

const PARAM_NAMES = ['search', 'status', 'balance'];

function AdvertiserList() {
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.advertisers.filters);
  const setFilters = useCallback((values) => dispatch(advertiserActions.setFilters(values)), [dispatch]);
  const location = useLocation();
  const [updateAdvertiserStatus] = useUpdateAdvertiserStatusMutation();

  const tableRef = useRef();
  const pageRef = useRef(0);

  const {
    meta,
    refillBalanceData,
    billingHistoryData,
    toggleRefillBalanceModal,
    toggleBillingHistoryModal,
    refillBalanceByAdvertiserId,
    requestAdvertisers,
  } = useAdvertiserList(filters);

  useEffect(() => {
    if (location.state?.refillBalanceAdvertiserId) {
      toggleRefillBalanceModal({ advertiserId: location.state.refillBalanceAdvertiserId, open: true });
    }
  }, [toggleRefillBalanceModal, location.state]);

  const params = useGetParameters(PARAM_NAMES);
  const { debouncedReplaceQueryParams, replaceQueryParams } = useQueryParams();

  const refFilters = useRef(filters);

  useEffect(() => {
    if (Object.keys(params).length) {
      const filterValues = { ...INITIAL_FILTERS, ...params };
      setFilters(filterValues);
      refFilters.current = filterValues;
    } else {
      replaceQueryParams(refFilters.current);
    }
  }, [params, setFilters, replaceQueryParams]);

  const refreshAdvertiserList = useCallback(() => {
    if (!tableRef.current) return;
    tableRef.current.onQueryChange();
  }, []);

  const handleChangeFilters = useCallback((values) => {
    pageRef.current = 0;
    setFilters(values);
    refFilters.current = values;
    debouncedReplaceQueryParams(values, refreshAdvertiserList);
  }, [setFilters, debouncedReplaceQueryParams, refreshAdvertiserList]);

  const resetFilters = useCallback(() => {
    handleChangeFilters(INITIAL_FILTERS);
  }, [handleChangeFilters]);

  const refillBalance = useCallback(async (...args) => {
    await refillBalanceByAdvertiserId(...args);
    refreshAdvertiserList();
  }, [refillBalanceByAdvertiserId, refreshAdvertiserList]);

  const updateStatus = useCallback((id, status) => {
    updateAdvertiserStatus({ id, data: JSON.stringify(status) }).unwrap().then(() => (tableRef?.current?.onQueryChange()));
  }, [updateAdvertiserStatus]);

  const isEditable = hasPermission([UserPermissions.types.VISTA_ADVERTISER_WRITE]);

  const onChangeFilters = (name) => (value) => {
    handleChangeFilters({ ...filters, [name]: value });
  };

  // reset filters on leave
  useEffect(() => () => {
    pageRef.current = 0;
    refFilters.current = INITIAL_FILTERS;
    setFilters(INITIAL_FILTERS);
  }, [setFilters]);

  return (
    <>
      <ProfileHeader filters={ filters } onChangeFilters={ onChangeFilters } />
      <Grid container spacing={ 2 } justifyContent="space-between" className="mb-5">
        <Grid item>
          <PageTitle title="Advertisers Management" showBreadcrumbs />
        </Grid>
        {isEditable && (
          <Grid item>
            <Button
              component={ Link }
              to="add"
              variant="contained"
              color="primary"
            >
              Add Advertiser
            </Button>
          </Grid>
        )}
      </Grid>

      <div className="grayContainer">
        <Grid container spacing={ 3 } alignItems="center" className="mb-3">
          <Filters
            values={ filters }
            onChange={ handleChangeFilters }
            onReset={ resetFilters }
          />
        </Grid>
        <TableAdvertisers
          tableRef={ tableRef }
          pageRef={ pageRef }
          getAdvertiserList={ requestAdvertisers }
          updateStatus={ updateStatus }
          meta={ meta }
          openRefillBalanceModal={ toggleRefillBalanceModal }
          openBillingHistoryModal={ toggleBillingHistoryModal }
        />

        <BillingHistoryModal
          isOpen={ billingHistoryData.isOpen }
          advertiserId={ billingHistoryData.advertiserId }
          handleClose={ toggleBillingHistoryModal }
          openRefillBalanceModal={ toggleRefillBalanceModal }
        />

        <RefillBalanceModal
          isOpen={ refillBalanceData.isOpen }
          handleClose={ toggleRefillBalanceModal }
          handleSubmit={ refillBalance }
          advertiserId={ refillBalanceData.advertiserId }
        />
      </div>
    </>
  );
}

export default memo(AdvertiserList);
