import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { getTimeZone } from 'libs/getTimeZone';
import { useGetCurrentUserMutation, useGetTimeZonesQuery, useSignupMutation } from 'api/apiSlice';
import { emitGoogleEvent, setUserIdForAnalitics } from 'libs/gtag';
import { tryParseInt } from 'libs/tryParseInt';
import EVENTS from '../../../constants/analitics';

const useSignup = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { data: { timeZones = [] } = {} } = useGetTimeZonesQuery();
  const [getCurrentUser] = useGetCurrentUserMutation();
  const [signup] = useSignupMutation();

  const signupHandler = useCallback(async (values, finallyCallback) => {
    try {
      const timeZone = getTimeZone(timeZones);
      const requestValues = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        timeZone: timeZone || values.timeZone,
        roleId: tryParseInt(values.roleId),
        phone: values.phone,
        company: values.companyName,
      };

      await signup(requestValues).unwrap();
      const message = 'Account created. A link to set your password has been sent to the specified e-mail address.';
      enqueueSnackbar(message, { variant: 'success' });

      const { data: user = {} } = await getCurrentUser();
      setUserIdForAnalitics(user.id);
      emitGoogleEvent(EVENTS.signUp);
    } catch (e) {
      const { message, validationErrors } = e.response.data || {};
      const validationErrorMessage = (validationErrors || []).find((item) => item.message)?.message;
      enqueueSnackbar(validationErrorMessage || message, { variant: 'error' });
    } finally {
      finallyCallback();
    }
  }, [timeZones, signup, enqueueSnackbar, getCurrentUser]);

  return { signup: signupHandler };
};

export default useSignup;
