import React, { useCallback, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

// Material
import { Grid, Typography } from '@mui/material';

// Modules
import PageTitle from 'modules/PageTitle/PageTitle';

// Dictionaries
import CampaignTypesDictionary from 'constants/dictionary/campaignTypesDictionary';
import CampaignStatusesDictionary from 'constants/dictionary/campaignStatusesDictionary';
import accountTypesDictionary from 'constants/dictionary/accountTypesDictionary';

// Components
import StatusTableCell from 'modules/_Table/TableCell/StatusTableCell/StatusTableCell';
import usePermissions from 'hooks/usePermissions';
import ProfileHeader from 'modules/ProfileHeader/ProfileHeader';
import classesLink from 'modules/_Router/RouterLink/RouterLink.module.scss';
import Prompt from 'modules/Prompt/Prompt';
import useAdvertiserBalance from 'modules/AdvertiserBalance/useAdvertiserBalance';
import Steps from './Steps/Steps';

// Hooks
import useAddCampaign from './useAddCampaign';

// Styles
import classes from './AddCampaign.module.scss';
import RefillBalanceModal from '../../Advertisers/AdvertiserPopups/Popups/RefillBalanceModal';
import { UserClass } from '../../../classes/userClass';
import { useGetCurrentUserMutation } from '../../../api/apiSlice';

function AddCampaign() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const params = useParams();

  const [, { data: user = new UserClass() }] = useGetCurrentUserMutation({ fixedCacheKey: 'current-user' });

  // State
  const permissions = usePermissions();
  const [editable, setEditable] = useState(permissions.campaignWrite);

  const id = state?.id || params?.id;
  const isCopy = Boolean(state?.id);

  const { formik, isLoading, isDisabled } = useAddCampaign({ id, systemType: CampaignTypesDictionary.types.AUDIENCE, setEditable, isCopy });
  const [isOpen, setIsOpen] = useState(false);
  const { fillAdvertiserBalance } = useAdvertiserBalance();

  const pageTitle = formik.values?.id ? 'Edit an Ad Campaign' : 'Create an Ad Campaign';

  const handlePublishCampaign = useCallback(async () => {
    await formik.handleSubmit();
    if (user.roleId === accountTypesDictionary.types.ADVERTISER) {
      setIsOpen(true);
    } else {
      navigate('/campaigns');
    }
  }, [formik, navigate, user]);

  const handlePayment = useCallback(async (advId, values) => {
    const navigation = {
      successUrl: `${window.location.origin}/campaigns`,
      cancelUrl: `${window.location.origin}/campaigns`,
    };
    await fillAdvertiserBalance(advId, values, navigation);
  }, [fillAdvertiserBalance]);

  const handleCancel = useCallback(() => {
    setIsOpen((prevState) => !prevState);
    navigate('/campaigns');
  }, [navigate]);

  if (isLoading) return null;

  return (
    <>
      <ProfileHeader />
      <Grid container className="mb-5" wrap="nowrap">
        <Grid item container spacing={ 4 }>
          <Grid item>
            <PageTitle title={ pageTitle } showBreadcrumbs lastElementName={ formik.values?.id && formik.values.name } />
          </Grid>

          {formik.initialValues?.id && (
          <Grid item className={ classes.statusBadge }>
            <StatusTableCell
              statusId={ formik.initialValues.status }
              statusName={ CampaignStatusesDictionary.hashMap[formik.initialValues.status] }
            />
          </Grid>
          )}
        </Grid>
      </Grid>

      <div className={ classes.container }>
        <Steps
          formik={ formik }
          editable={ editable }
          isEditForm={ !!id }
          isDisabled={ isDisabled }
          handlePublishCampaign={ handlePublishCampaign }
        />
      </div>
      <Typography className={ classes.terms }>
        By advertising on Telly, you agree to the
        <a className={ classesLink.link } href="/terms" target="_blank">
          &nbsp;terms and conditions
        </a>
      </Typography>
      <RefillBalanceModal
        isOpen={ isOpen }
        advertiserId={ formik.values.advertiser }
        handleClose={ handleCancel }
        handleSubmit={ handlePayment }
      />
      <Prompt when={ formik.dirty && !formik.isSubmitting && !formik.submitCount } />
    </>
  );
}

export default AddCampaign;
